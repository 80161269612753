import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import { slugify } from "../../../utils"
import EmailLink from "../../atoms/EmailLink"
import PhoneLink from "../../atoms/PhoneLink"
import PostalAddress from "../../atoms/PostalAddress"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import MediaTextBlock, { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
import DatoImage from "../DatoImage"
import * as styles from "./DatoContactLocation.module.scss"

const DatoContactLocationBlock = (props: Queries.DatoContactLocationBlockFragment) => {
  //console.log('Rendering DatoContactLocation', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("contact", locale)
  const data = props.contactLocation

  if (!data) return null

  return (
    <MediaTextBlock
      id={slugify(data.title)}
      layout={(props.layout || "Tile") as MediaTextBlockLayout}
      contentBackground={(props.textBackground || "BrandWhite") as HarviaBackgroundColor}
      media={props.image && <DatoImage {...props.image} alt={props.image.alt || ""} />}
      content={
        <>
          <h2>{data.title}</h2>
          {data.phone && (
            <div>
              <PhoneLink className={styles.phone} phone={data.phone} labelPrefix={t("abbrTelephone", "Tel.")} />
            </div>
          )}
          {data.email && (
            <div>
              <EmailLink email={data.email} labelPrefix={t("abbrEmail", "Email")} />
            </div>
          )}
          {
            <div>
              <PostalAddress labelPrefixPo={t("abbrPoBox", "P.O.Box")} {...data} />
            </div>
          }
          {data.availabilityDetails && (
            <div>
              <h4>{t("availability", "Availability")}</h4>
              <p>{data.availabilityDetails}</p>
            </div>
          )}
          {data.callRates && (
            <div>
              <h4>{t("callRates", "Call rates")}</h4>
              <p>{data.callRates}</p>
            </div>
          )}
        </>
      }
    />
  )
}

export default DatoContactLocationBlock

export const query = graphql`
  fragment DatoContactLocationBlock on DatoCmsContactLocationBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    contactLocation {
      ...DatoContactLocation
    }
    image {
      ...DatoImageDefault
    }
    layout
    textBackground
  }
`
